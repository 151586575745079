import { defineStore } from 'pinia'
import { ref } from 'vue'

export const useAppStore = defineStore('app', () => {
  const updateAvailable = ref(false)
  const registration = ref<ServiceWorkerRegistration | undefined>()
  // Check for existing registration when the component is mounted
  const initSWRegistration = async () => {
    if ('serviceWorker' in navigator) {
      const reg = await navigator.serviceWorker.getRegistration()
      if (reg) {
        console.log(
          '[APP STORE] => initSWRegistration => Existing service worker registration found',
          reg
        )
        registration.value = reg
      }

      return registration.value
    }
  }

  const immediateCheckForUpdates = async () => {
    await initSWRegistration()

    console.log(
      '[APP STORE] => immediateCheckForUpdates =>  registration.value',
      registration.value
    )

    if (!registration.value) {
      return
    }

    console.log(
      '[APP STORE] => immediateCheckForUpdates =>  await registration.value.update() fired'
    )

    await registration.value.update()
    console.log(
      '[APP STORE] => immediateCheckForUpdates =>  ! !!registration.value.waiting',
      !!registration.value.waiting
    )
    return !!registration.value.waiting
  }

  return {
    updateAvailable,
    registration,
    initSWRegistration,
    immediateCheckForUpdates
  }
})
