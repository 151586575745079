export interface IUserData {
  access_token: string | null
  token_type: string | null
  user: IUser | null
}

export interface IUser {
  _id: string
  username: string
  full_name?: string
  active?: boolean
  admin?: boolean
  avatar?: string
  lang?: string
  activeFeatures: { notesLengthChoice: true }
  creationDate: string
  defaultNoteType: 'soap'
  sttModel: 'gladia'
}

export interface IResetPasswordForm {
  reset_password_token: string
  new_password: string
  confirm_password: string
}

export interface ISignUpForm {
  username: string
  full_name: string
  password: string
}

export interface ILoginForm {
  username: string
  password: string
}

export interface IConsultation {
  _id: string
  recording: string
  recordingMode: string
  status: 'ready' | 'error' | 'progress'
  userId: string
  username: string
  creationDate: string
  modificationDate: string
  active: boolean
  transcript: string
  speaker0: string
  speaker1: string
  description: string
  summary: string
  noteType: string
  lang?: string | null
  lostSeconds: number
  activeNotes: ENoteLength
  consultationDuration: number
}

export enum ENoteLength {
  SHORT = 'short',
  STANDARD = 'standard',
  LONG = 'long'
}
export type WSEvent = {
  event: 'created' | 'deleted' | 'ready'
  consultationId: string
}

export interface IPagination {
  page: number
  size: number
}
