<template>
  <div v-if="isFeedbackAvailable" class="inline-flex items-stretch gap-2.5">
    <button
      type="button"
      :disabled="loading"
      class="relative flex items-center gap-3 rounded-md border border-accent-500 bg-white/20 px-4 py-2 text-accent-500 hover:bg-accent-500/30"
      @click="handleThumbsUp"
    >
      <HandThumbUpIcon class="h-6 w-6" />
    </button>

    <button
      type="button"
      :disabled="loading"
      class="relative flex items-center gap-3 rounded-md border border-accent-500 bg-white/20 px-4 py-2 text-accent-500 hover:bg-accent-500/30"
      @click="handleThumbsDown"
    >
      <HandThumbDownIcon class="h-6 w-6" />
    </button>

    <BaseDialog v-model:visible="dialogVisible" :title="dialogTitle" type="info">
      <label for="feedback-textarea" class="mb-3 mt-6 block">
        {{ $t('dialogs.transcriptionsConsultationFeedback.contentLabel') }}
      </label>

      <BaseTextarea
        id="feedback-textarea"
        v-model="feedbackText"
        class="min-h-[200px]"
        :placeholder="$t('dialogs.transcriptionsConsultationFeedback.contentPlaceholder')"
      />

      <template #actions>
        <button
          type="button"
          class="flex items-center justify-center gap-3 rounded-md border border-accent-500 bg-accent-500/10 px-4 py-2 text-xl font-semibold text-accent-500 shadow-sm hover:bg-accent-500/30 sm:ml-3 sm:w-auto xl:text-base"
          :disabled="!feedbackText || loading"
          @click="handleSendFeedbackText"
        >
          {{ $t('dialogs.transcriptionsConsultationFeedback.actions.submit') }}
        </button>
      </template>
    </BaseDialog>
  </div>
</template>

<script lang="ts" setup>
import { onMounted, ref } from 'vue'
import { toaster } from '@/utils/toaster'
import consultationDataService from '@/services/consultationDataService'
import { HandThumbDownIcon, HandThumbUpIcon } from '@heroicons/vue/24/solid'
import BaseTextarea from '@/components/base/BaseTextarea.vue'
import BaseDialog from '@/components/base/BaseDialog.vue'
import { logError } from '@/utils/error-logger'
import { useI18n } from 'vue-i18n'

interface Props {
  consultationId: string
}
const props = defineProps<Props>()
const { t } = useI18n()

const THUMBS_UP_TITLE = t('dialogs.transcriptionsConsultationFeedback.thumbsUpTitle')
const THUMBS_DOWN_TITLE = t('dialogs.transcriptionsConsultationFeedback.thumbsDownTitle')

const dialogVisible = ref(false)

const dialogTitle = ref(THUMBS_UP_TITLE)
const thumbUp = ref(true)
const feedbackText = ref('')
const isFeedbackAvailable = ref(false)
const loading = ref(false)

const handleThumbsDown = async () => {
  dialogTitle.value = THUMBS_DOWN_TITLE
  dialogVisible.value = true
  thumbUp.value = false

  try {
    loading.value = true

    await consultationDataService.giveSummaryFeedback({
      consultationId: props.consultationId,
      thumbUp: thumbUp.value,
      feedback: ''
    })
  } catch (error: any) {
    logError(
      "Error: user can't send negative feedback",
      'SummaryFeedback.vue:handleThumbsDown()',
      error?.response?.data
    )
    toaster.error(t('dialogs.transcriptionsConsultationFeedback.toaster.feedbackNotSent'))
  } finally {
    loading.value = false
  }
}

const handleThumbsUp = async () => {
  dialogTitle.value = THUMBS_UP_TITLE
  dialogVisible.value = true
  thumbUp.value = true

  try {
    loading.value = true

    await consultationDataService.giveSummaryFeedback({
      consultationId: props.consultationId,
      thumbUp: thumbUp.value,
      feedback: ''
    })
  } catch (error: any) {
    logError(
      "Error: user can't send positive feedback",
      'SummaryFeedback.vue:handleThumbsUp()',
      error?.response?.data
    )
    toaster.error(t('dialogs.transcriptionsConsultationFeedback.toaster.feedbackNotSent'))
  } finally {
    loading.value = false
  }
}

const handleSendFeedbackText = async () => {
  try {
    loading.value = true

    await consultationDataService.giveSummaryFeedback({
      consultationId: props.consultationId,
      thumbUp: thumbUp.value,
      feedback: feedbackText.value
    })

    toaster.success(t('dialogs.transcriptionsConsultationFeedback.toaster.thanksForFeedback'))

    isFeedbackAvailable.value = false
    dialogVisible.value = false
  } catch (error: any) {
    logError(
      "Error: user can't send text of feedback",
      'SummaryFeedback.vue:handleSendFeedbackText()',
      error?.response?.data
    )
    toaster.success(t('dialogs.transcriptionsConsultationFeedback.toaster.thanksForFeedback'))
    toaster.error(t('dialogs.transcriptionsConsultationFeedback.toaster.feedbackNotSent'))
  } finally {
    loading.value = false
  }
}

onMounted(async () => {
  try {
    const { data } = await consultationDataService.getSummaryFeedback(props.consultationId)
    isFeedbackAvailable.value = !data?.feedback
  } catch (error: any) {
    logError(
      'Error with fetching feedback by consultation id',
      'SummaryFeedback.vue:onMounted()',
      error?.response?.data
    )
    toaster.error(t('dialogs.transcriptionsConsultationFeedback.toaster.canNotBeRetrieved'))
  }
})
</script>
