<template>
  <ErrorCatcher>
    <ReloadPrompt />
    <Toaster position="top-right" />
    <component :is="$route.meta.layoutComponent">
      <RouterView />
    </component>
  </ErrorCatcher>
</template>

<script lang="ts" setup>
import { onMounted } from 'vue'
import { Toaster } from 'vue-sonner'
import { useWakeLock } from '@vueuse/core'
import ReloadPrompt from '@/components/ReloadPrompt.vue'
import ErrorCatcher from '@/components/ErrorCatcher.vue'
import { toaster } from '@/utils/toaster'
import { useI18n } from 'vue-i18n'
import useCookieLocale from '@/composables/useCookieLocale'
import { useUserDataStore } from '@/stores/user'

const { isSupported } = useWakeLock()
const { t, locale } = useI18n({ useScope: 'global' })
const { currentLang } = useCookieLocale()
const userStore = useUserDataStore()

onMounted(async () => {
  if (userStore.userData?.user?.lang || currentLang.value) {
    locale.value = (userStore.userData?.user?.lang || currentLang.value) as string
  } else {
    currentLang.value = navigator.language
  }

  if (!isSupported.value) {
    toaster.warn(t('wakeLock.notSupported'))
    return
  }
})
</script>
