<template>
  <slot />
</template>

<script lang="ts" setup>
import { onErrorCaptured } from 'vue';
import { logError } from '@/utils/error-logger';

onErrorCaptured((err, instance, info) => {
  
  console.error(err, instance, info)
  // @ts-ignore
  const componentName = instance?.type?.name || 'Anonymous';
  
  logError(info, componentName, err)
  
  return false;
})
</script>

