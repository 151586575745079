<template>
  <BaseTooltip v-model="show" tooltip="Copied" :duration="1500">
    <button
      class="flex items-center gap-3 rounded-md border border-accent-500 bg-accent-500/10 px-4 py-2 text-accent-500 hover:bg-accent-500/30"
      v-bind="$attrs"
      @click.stop.prevent="copyText"
    >
      <template v-if="!$slots.default">
        <DocumentDuplicateIcon class="h-8 w-8" />
        {{ $t('consultationIndexView.details.copy') }}
      </template>
      <slot />
    </button>
  </BaseTooltip>
</template>

<script lang="ts" setup>
import { ref } from 'vue'
import { toaster } from '@/utils/toaster'
import BaseTooltip from '@/components/base/BaseTooltip.vue'
import { DocumentDuplicateIcon } from '@heroicons/vue/24/outline'
import { logError } from '@/utils/error-logger'

interface Props {
  text?: string
}
const props = withDefaults(defineProps<Props>(), { text: '' })
const show = ref<boolean>(false)

const copyText = async () => {
  try {
    await navigator.clipboard.writeText(typeof props.text === 'string' ? props.text : '')

    show.value = true
  } catch (error: any) {
    logError(
      "Error: user can't copy to clip board, probably the browser doesn't support clipboard",
      'BaseCopyButton.vue:copyText()',
      error
    )
    toaster.error('Copy failed. Try using Chrome browser.')
  }
}
</script>
