<template>
  <div
    ref="consultationListRef"
    class="grow overflow-y-auto"
    :class="{ 'max-lg:!h-[calc(100dvh-180px)]': consultationsStore.consultationList?.length > 5 }"
  >
    <div class="px-8 pb-8">
      <template
        v-if="
          consultationsStore.loadingConsultationList && consultationsStore.pagination?.page === 1
        "
      >
        <div
          v-for="item in 6"
          :key="item"
          class="mx-auto mt-2 w-full max-w-sm rounded-md border border-gray-300 p-4 shadow"
        >
          <div class="flex animate-pulse space-x-4">
            <div class="h-10 w-10 rounded-full bg-slate-200"></div>
            <div class="flex-1 space-y-6 py-1">
              <div class="h-2 rounded bg-slate-200"></div>
              <div class="space-y-3">
                <div class="grid grid-cols-3 gap-4">
                  <div class="col-span-2 h-2 rounded bg-slate-200"></div>
                  <div class="col-span-1 h-2 rounded bg-slate-200"></div>
                </div>
                <div class="h-2 rounded bg-slate-200"></div>
              </div>
            </div>
          </div>
        </div>
      </template>

      <ul v-else>
        <li
          v-for="consultation in consultationsStore.consultationList || []"
          :key="consultation?._id"
          class="group border-gray-200 py-0.5 lg:first:border-t"
        >
          <div
            class="border-gray2-200 -mx-5 flex items-center justify-between gap-3 rounded-[14px] px-5 py-4 hover:bg-gray-300 group-hover:border-t-0"
            :class="{
              '!bg-[#E7E7E7]': consultationsStore.selectedConsultationId === consultation?._id
            }"
            @click="consultationsStore.selectConsultation(consultation?._id)"
          >
            <div class="grow overflow-hidden">
              <div class="max-w-full truncate pr-5 text-xl font-bold">
                {{ consultation?.description }}
              </div>
              <div
                class="mt-1 flex flex-wrap items-center gap-2 text-xs font-medium leading-3 text-gray-700"
              >
                <span v-if="consultation?.creationDate">
                  {{ dayjs(consultation?.creationDate).format('MMM DD HH:mm') }}
                </span>
                <!-- <span
                  v-if="consultation?.duration"
                  class="rounded-[4px] border border-[#586D68] px-1.5 py-0.5 text-[11px]"
                >
                  {{ consultation?.duration }}
                </span> -->

                |
                <span
                  v-if="isReady(consultation)"
                  class="rounded-[4px] border border-green-500 bg-green-500/10 px-1.5 py-0.5 text-[11px]"
                >
                  ready
                </span>
                <span
                  v-else-if="isBugged(consultation)"
                  class="rounded-[4px] border border-red-500 bg-red-500/10 px-1.5 py-0.5 text-[11px]"
                >
                  error
                </span>
                <span
                  v-else
                  class="rounded-[4px] border border-yellow-500 bg-yellow-500/10 px-1.5 py-0.5 text-[11px]"
                >
                  in progress
                </span>
              </div>
            </div>

            <BaseDialog
              type="error"
              :title="$t('dialogs.deleteConsultation.title')"
              :content="consultation.description"
              :okText="$t('dialogs.deleteConsultation.actions.ok')"
              @ok="handleRemoveNote(consultation?._id)"
            >
              <template #activator="{ toggleVisibility }">
                <button
                  type="button"
                  class="h-8 w-8 shrink-0 hover:text-red-500"
                  @click.stop="toggleVisibility"
                >
                  <TrashIcon class="h-6 w-6" />
                </button>
              </template>

              <template #icon>
                <ExclamationTriangleIcon class="h-6 w-6" aria-hidden="true" />
              </template>
            </BaseDialog>
          </div>
        </li>

        <li
          v-if="consultationsStore.loadingConsultationList"
          class="relative h-12 overflow-hidden rounded-[14px]"
        >
          <BaseSpinner />
        </li>
      </ul>
    </div>
  </div>
</template>

<script lang="ts" setup>
import dayjs from 'dayjs'
import { TrashIcon, ExclamationTriangleIcon } from '@heroicons/vue/24/outline'
import { IConsultation } from '@/types'
import BaseDialog from '@/components/base/BaseDialog.vue'
import BaseSpinner from './base/BaseSpinner.vue'
import { ref, nextTick } from 'vue'
import { useInfiniteScroll } from '@vueuse/core'
import { useConsultationsStore } from '@/stores/consultations'

const consultationsStore = useConsultationsStore()

const consultationListRef = ref<HTMLElement | null>(null)

useInfiniteScroll(
  consultationListRef,
  () => {
    // load more
    if (consultationListRef.value) {
      nextTick(() => {
        consultationsStore.paginateConsultationList({
          ...consultationsStore.pagination,
          page: (consultationsStore.pagination?.page || 1) + 1
        })
      })
    }
  },
  { distance: 10, interval: 1000, canLoadMore: () => !consultationsStore.isStopPaginate }
)

function handleRemoveNote(id: string) {
  consultationsStore.removeConsultation(id)
}

function isReady(consultation?: IConsultation) {
  return consultation?.summary
}

function isBugged(consultation?: IConsultation) {
  return consultation?.status === 'error'
}
</script>
